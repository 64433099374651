// import { Map } from 'immutable';

import authHelper from "./authHelper";
export { Roles } from "./roles";

export function clearToken() {
   localStorage.removeItem('token');
}

export function setToken(token) {
   var type = typeof token;
   if(type === 'object') {
      localStorage.setItem('token', token.access_token);
   } else {
      localStorage.setItem('token', token);
   }
}

export function getToken(logout) {
   try {
      var tok = localStorage.getItem('token');
      var checked = authHelper.checkExpirity(tok);
      if(checked == "LOGOUT") {
         return logout();
      }
      return tok;
   }
   catch (err) {
      clearToken();
   }
}


export function getTicketCredentials() {
   try {
      return JSON.parse(localStorage.getItem('ticketCredentials'));
   }
   catch (err) {
      clearTicketCredentials();
   }
}

export function clearTicketCredentials() {
   localStorage.removeItem('ticketCredentials');
}

export function timeDifference(givenTime) {
   givenTime = new Date(givenTime);
   const milliseconds = new Date().getTime() - givenTime.getTime();
   const numberEnding = number => {
      return number > 1 ? 's' : '';
   };
   const number = num => (num > 9 ? '' + num : '0' + num);
   const getTime = () => {
      let temp = Math.floor(milliseconds / 1000);
      const years = Math.floor(temp / 31536000);
      if (years) {
         const month = number(givenTime.getUTCMonth() + 1);
         const day = number(givenTime.getUTCDate());
         const year = givenTime.getUTCFullYear() % 100;
         return `${day}-${month}-${year}`;
      }
      const days = Math.floor((temp %= 31536000) / 86400);
      if (days) {
         if (days < 28) {
            return days + ' day' + numberEnding(days);
         } else {
            const months = [
               'Jan',
               'Feb',
               'Mar',
               'Apr',
               'May',
               'Jun',
               'Jul',
               'Aug',
               'Sep',
               'Oct',
               'Nov',
               'Dec',
            ];
            const month = months[givenTime.getUTCMonth()];
            const day = number(givenTime.getUTCDate());
            return `${day} ${month}`;
         }
      }
      const hours = Math.floor((temp %= 86400) / 3600);
      if (hours) {
         return `${hours} hour${numberEnding(hours)} ago`;
      }
      const minutes = Math.floor((temp %= 3600) / 60);
      if (minutes) {
         return `${minutes} minute${numberEnding(minutes)} ago`;
      }
      return 'a few seconds ago';
   };

   return getTime();
}

