const ContextCodes = {
    Trips: "CTrips",
    CurrentAcc: "CCurrentAcc",
    Orders: "COrders",
    Shipment: "CShipment",
    Invoices: "CInvoices",
    OtherDocuments: "COtherDocuments",
    ContractLines: "CContractLines",
}

const ContextArr = [
    {
        label: "Trips",
        value: ContextCodes.Trips
    },
    {
        label: "CurrentAcc",
        value: ContextCodes.CurrentAcc
    },
    {
        label: "Orders",
        value: ContextCodes.Orders
    },
    {
        label: "Shipment",
        value: ContextCodes.Shipment
    },
    {
        label: "Invoices",
        value: ContextCodes.Invoices
    },
    {
        label: "OtherDocuments",
        value: ContextCodes.OtherDocuments
    },
    {
        label: "ContractLines",
        value: ContextCodes.ContractLines
    }
]

export {
    ContextCodes,
    ContextArr
}