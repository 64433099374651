import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';

import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
   return (
      <Route
         {...rest}
         render={props =>
            isLoggedIn ?
               (<Component {...props} />)
               :
               (<Redirect to={{ pathname: '/signin', state: { from: props.location } }} />)
         }
      />
   );
};

const PublicRoutes = ({ history, isLoggedIn }) => {
   if (history.action === "POP" && !history.location.pathname.includes('RecoverPassword')) {
      if (window.location.pathname != "/dashboard/Home" && window.performance.navigation.type != 1 && isLoggedIn) {
         window.location.href = window.origin + "/dashboard/Home";
      }
   }

   return (
      <ConnectedRouter history={history}>
         <div>
            <Route
               exact
               path={'/'}
               component={asyncComponent(() => import('./containers/Page/signin'))}
            />

            <Route
               exact
               path={'/404'} // path={'/*'}
               component={asyncComponent(() => import('./containers/Page/404'))}
            />

            <Route
               exact
               path={'/500'}
               component={asyncComponent(() => import('./containers/Page/500'))}
            />

            <Route
               exact
               path={'/signin'}
               component={asyncComponent(() => import('./containers/Page/signin'))}
            />

            <Route
               exact
               path={'/Request'}
               component={asyncComponent(() => import('./containers/Page/Request'))}
            />

            <Route
               exact
               path={'/RecoverPassword'}
               component={asyncComponent(() => import('./containers/Page/RecoverPwd'))}
            />

            <RestrictedRoute
               path="/dashboard"
               component={App}
               isLoggedIn={isLoggedIn}
            />
         </div>
      </ConnectedRouter>
   );
};

export default connect(state => ({ isLoggedIn: state.Auth.token !== null }))(PublicRoutes);
