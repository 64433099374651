import styled from 'styled-components';
import { palette } from 'styled-theme';
import Buttons from '../../components/uielements/button';

const TableDemoStyle = styled.div`
  .ant-tabs-content {
    margin-top: 40px;
  }

  .ant-tabs-nav {
    > div {
      color: ${palette('secondary', 2)};

      &.ant-tabs-ink-bar {
        background-color: ${palette('primary', 0)};
      }

      &.ant-tabs-tab-active {
        color: ${palette('primary', 0)};
      }
    }
  }
`;

const StatusTag = styled.span`
  padding: 0 5px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: ${palette('primary', 0)};
  font-size: 12px;
  color: #ffffff;
  text-transform: capitalize;

  &.success {
    background-color: ${palette('success', 0)};
  }

  &.error {
    background-color: ${palette('error', 0)};
  }

  &.true {
    background-color: ${palette('success', 0)};
  }

  &.false {
    background-color: ${palette('error', 0)};
  }

  &.confirmed {
    background-color: ${palette('success', 0)};
  }

  &.confirming {
    background-color: ${palette('warning', 0)};
  }

  &.approved {
    background-color: ${palette('success', 0)};
  }

  &.pending {
    background-color: ${palette('warning', 0)};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;
`;

const ButtonHolders = styled.div``;

const ComponentTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${palette('text', 0)};
  margin: 5px 0;
`;

const ActionBtn = styled(Buttons)`
  @media screen and (min-width: 0px) and (max-width: 767px) {
    .mobile-hide{ display: none; }
  }
  
  && {
    padding: 0 12px;
    margin-right: 15px;  

    &:last-child {
      margin-right: 0;
    }
    i {
      font-size: 17px;
      color: ${palette('text', 1)};
    }

    &:hover {
      i {
        color: inherit;
      }
    }
  }
`;

const Fieldset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  font-size: 13px;
  color: ${palette('text', 1)};
  line-height: 1.5;
  font-weight: 500;
  padding: 0;
  margin: 0 0 8px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-content: center;

  a {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }

    i {
      font-size: 18px;
      color: ${palette('primary', 0)};

      &:hover {
        color: ${palette('primary', 4)};
      }
    }

    &.deleteBtn {
      i {
        color: ${palette('error', 0)};

        &:hover {
          color: ${palette('error', 2)};
        }
      }
    }
  }
`;

const Form = styled.div``;

export { TableDemoStyle, ActionBtn, Fieldset, Label, Form, TitleWrapper, ButtonHolders, ActionWrapper, ComponentTitle, StatusTag };
