import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import axios from "axios";
import ReactFlagsSelect from 'react-flags-select';

import 'react-flags-select/css/react-flags-select.css';
import { SERVER_ADDRESS } from '../../settings';
import { themeConfig } from "../../settings";
import appActions from "../../redux/app/actions";
import config from "../../redux/languageSwitcher/config";
import languageActions from "../../redux/languageSwitcher/actions";
import themes from "../../settings/themes";
import TopbarNotification from "./topbarNotification";
import TopbarSearch from "./topbarSearch";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import { getToken } from '../../helpers/utility';

var TOKEN = "";

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const { changeLanguage } = languageActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
   constructor(props) {
      super(props);

      this.state = {
         langCodes: [],
         selectedLang: null,
         isLoading: true,
         isGlobalSearchActive: false,
         flagCode: "ES",
         hideNotifications: false,
      };
      // this.changeLang = this.changeLang.bind(this);
   }

   render() {
      const { toggleCollapsed, changeLanguage, language } = this.props;
      const collapsed = this.props.collapsed && !this.props.openDrawer;
      const styling = {
         background: customizedTheme.backgroundColor,
         position: "fixed",
         width: "100%",
         height: 60
      };

      const rightIcons = {
         marginBottom: 0,
      };

      return (
         <TopbarWrapper>
            <Header
               style={styling}
               className={
                  collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
               }
            >
               <div className="isoLeft">
                  <button
                     className={
                        collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
                     }
                     style={{ color: "#FFF" }}//{{ color: customizedTheme.textColor }}
                     onClick={toggleCollapsed}
                  />
               </div>

               <ul className="isoRight" style={rightIcons}>
                  {this.state.isGlobalSearchActive &&
                     <li className="isoSearch">
                        <TopbarSearch />
                     </li>
                  }
                  {
                     this.state.hideNotifications == false ?
                        <li
                           onClick={() => this.setState({ selectedItem: "notification" })}
                           className="isoNotify"
                        >
                           <TopbarNotification />
                        </li> : null
                  }
                  <li
                  // onClick={() => this.setState({ selectedItem: "user" })}
                  // className="isoUser"
                  >
                     <div hidden={this.state.isLoading}>
                        <ReactFlagsSelect
                           onSelect={(countryCode) => {
                              var aux = 'english';
                              config.options.find(x => {
                                 if (countryCode == x.code) {
                                    aux = x.languageId;
                                 }
                              });

                              this.setState({ selectedLang: countryCode }, () => {
                                 changeLanguage(aux);
                              });
                           }}
                           ref="userFlag"
                           countries={this.state.langCodes}
                           defaultCountry={this.state.flagCode}
                           showSelectedLabel={false}
                           showOptionLabel={false} />
                     </div>
                  </li>
                  <li
                     onClick={() => this.setState({ selectedItem: "user" })}
                     className="isoUser"
                  >
                     <TopbarUser locale={language.locale} />
                  </li>
               </ul>
            </Header>
         </TopbarWrapper>
      );
   }

   componentWillReceiveProps(nextProps) {
      this.refs.userFlag.updateSelected(nextProps.language.code);
      this.setState({
         flagCode: nextProps.language.code
      });
      this.setState({ isLoading: false });
   }

   componentDidMount() {
      TOKEN = getToken();
      this.setState({ isLoading: true });
      this.getUserLanguage();
      this.mountLangArray();
      this.getGlobalSearchState();
      this.ShowsNotifications();
   }

   mountLangArray() {
      var aux = [];
      var configOptions = config.options;

      configOptions.map(x => {
         aux.push(x.code);
      });

      this.setState({
         langCodes: aux,
         // flagCode: this.props.language.code
      });
   }

   changeLang = (countryCode) => {
      var aux = 'english';
      config.options.find(x => {
         if (countryCode == x.code) {
            aux = x.languageId;
         }
      });

      changeLanguage(aux);

      this.setState({ selectedLang: countryCode });
   }

   getGlobalSearchState = () => {
      axios
         .get(`${SERVER_ADDRESS}/api/Backoffice/Settings/GlobalSearchActive`, {
            headers: {
               Authorization: `Bearer ${TOKEN}`
            }
         })
         .then(data => {
            this.setState({ isGlobalSearchActive: data.data });
         })
   }

   getUserLanguage = () => {
      axios
         .get(`${SERVER_ADDRESS}/api/Account/GetUserLanguage`, {
            headers: {
               Authorization: `Bearer ${TOKEN}`
            }
         })
         .then(data => {
            var x = data.data.toUpperCase().slice(data.data.indexOf('-') + 1, data.data.length)
            this.setState({
               flagCode: x
            });
         });
   }

   ShowsNotifications = () => {
      axios.get(`${SERVER_ADDRESS}/api/Common/ShowsNotifications`, {
         headers: {
            Authorization: `Bearer ${TOKEN}`
         }
      }).then(x => {
         this.setState({
            hideNotifications: x.data
         });
      });
   }
}

export default connect(
   state => ({
      ...state.App,
      ...state.LanguageProvider,
   }),
   { toggleCollapsed, changeLanguage }
)(Topbar);
