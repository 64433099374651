import { SERVER_ADDRESS } from '../settings';
import { getToken } from "../helpers/utility";

const customHeader = () => ({
	'Content-Type': 'application/json',
	Accept: 'application/json',
	Authorization: 'Bearer ' + getToken(),
});

const base = (method, url, data = {}) => {
	return fetch(`${SERVER_ADDRESS}${url}`, {
		method,
		headers: customHeader(),
		body: JSON.stringify(data),
	})
		.then(response => {
			return response.json();
		})
		.then(res => res)
		.catch(exc => {  }) // fu
		.catch(error => ({ error: error }));
};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
	SuperFetch[method] = base.bind(null, method);
});

export default SuperFetch;
