import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import clone from 'clone';
import axios from "axios";
import { themeConfig } from '../../settings';
import appActions from '../../redux/app/actions';
import IntlMessages from '../../components/utility/intlMessages';
import Logo from '../../components/utility/logo';
import Menu from '../../components/uielements/menu';
import options from './options';
import Scrollbars from '../../components/utility/customScrollBar.js';
import SidebarWrapper from './sidebar.style';
import themes from '../../settings/themes';
import { SERVER_ADDRESS } from '../../settings';
import { getToken, Roles, getTicketCredentials } from '../../helpers/utility';

var TOKEN = "";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const {
   toggleOpenDrawer,
   changeOpenKeys,
   changeCurrent,
   toggleCollapsed
} = appActions;

const stripTrailingSlash = str => {
   if (str.substr(-1) === '/') {
      return str.substr(0, str.length - 1);
   }
   return str;
};

class Sidebar extends React.PureComponent {
   constructor(props) {
      super(props);

      this.state = {
         userRoles: [],
         userSideBarPerms: []
      };

      this.handleClick = this.handleClick.bind(this);
      this.onOpenChange = this.onOpenChange.bind(this);
   }

   handleClick(e) {
      this.props.changeCurrent([e.key]);
      if (this.props.app.view === 'MobileView') {
         setTimeout(() => {
            this.props.toggleCollapsed();
            this.props.toggleOpenDrawer();
         }, 100);
      }
   }

   onOpenChange(newOpenKeys) {
      const { app, changeOpenKeys } = this.props;
      const latestOpenKey = newOpenKeys.find(
         key => !(app.openKeys.indexOf(key) > -1)
      );
      const latestCloseKey = app.openKeys.find(
         key => !(newOpenKeys.indexOf(key) > -1)
      );
      let nextOpenKeys = [];
      if (latestOpenKey) {
         nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
      }
      if (latestCloseKey) {
         nextOpenKeys = this.getAncestorKeys(latestCloseKey);
      }
      changeOpenKeys(nextOpenKeys);
   }

   getAncestorKeys = key => {
      const map = {
         sub3: ['sub2']
      };
      return map[key] || [];
   };

   getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
      const { key, label, leftIcon, children, separatorTitle, external, space } = singleOption;
      const url = stripTrailingSlash(this.props.url);
      const ticketCredentials = getTicketCredentials();

      if (separatorTitle) {
         return (
            <Menu.Item key={key} disabled style={{ size: 25 }}>
               <span className="isoMenuHolder" style={submenuColor}>
                  <span className="nav-text">
                     <IntlMessages id={separatorTitle} />
                  </span>
               </span>
            </Menu.Item>
         );
      }

      if (space) {
         return (
            <div key={key} style={{ margin: "5px" }} />
         )
      }

      if (children) {
         return (
            <SubMenu
               key={key}
               title={
                  <span className="isoMenuHolder" style={submenuColor}>
                     <i className={leftIcon} />
                     <span className="nav-text">
                        <IntlMessages id={label} />
                     </span>
                  </span>
               }
            >
               {
                  children.map(child => {
                     const linkTo = child.withoutDashboard
                        ? `/${child.key}`
                        : `${url}/${child.key}`;

                     return (
                        <Menu.Item style={submenuStyle} key={child.key}>
                           <Link style={submenuColor} to={linkTo}>
                              <IntlMessages id={child.label} />
                           </Link>
                        </Menu.Item>
                     );
                  })
               }
            </SubMenu>
         );
      }

      return (
         <Menu.Item key={key}>
            {external ?
               (<a href={`${key === 'MyTickets' ? (ticketCredentials.url ? ticketCredentials.url : "http://veoliaargentina.sondeosglobal.com/login") : singleOption.url}`} target="_blank">
                  <span className="isoMenuHolder" style={submenuColor}>
                     <i className={leftIcon} />
                     <span className="nav-text">
                        <IntlMessages id={label} />
                     </span>
                  </span>
               </a>)
               :
               (<Link to={`${url}/${key}`}>
                  <span className="isoMenuHolder" style={submenuColor}>
                     <i className={leftIcon} />
                     <span className="nav-text">
                        <IntlMessages id={label} />
                     </span>
                  </span>
               </Link>)
            }
         </Menu.Item>
      );
   };

   render() {
      const { app, toggleOpenDrawer, height } = this.props;
      const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
      const { openDrawer } = app;
      const mode = collapsed === true ? 'vertical' : 'inline';
      const onMouseEnter = event => {
         if (openDrawer === false) {
            toggleOpenDrawer();
         }
         return;
      };
      const onMouseLeave = () => {
         if (openDrawer === true) {
            toggleOpenDrawer();
         }
         return;
      };
      const customizedTheme = themes[themeConfig.theme];
      const styling = {
         backgroundColor: customizedTheme.backgroundColor
      };
      const submenuStyle = {
         backgroundColor: 'rgba(0,0,0,0.3)',
         color: customizedTheme.textColor
      };
      const submenuColor = {
         color: customizedTheme.textColor
      };
      return (
         <SidebarWrapper>
            <Sider
               trigger={null}
               collapsible={true}
               collapsed={collapsed}
               width={240}
               className="isomorphicSidebar"
               onMouseEnter={onMouseEnter}
               onMouseLeave={onMouseLeave}
               style={styling}
            >
               <Logo collapsed={collapsed} />
               <Scrollbars style={{ height: height - 70 }}>
                  <Menu
                     onClick={this.handleClick}
                     theme="dark"
                     className="isoDashboardMenu"
                     mode={mode}
                     openKeys={collapsed ? [] : app.openKeys}
                     selectedKeys={app.current}
                     onOpenChange={this.onOpenChange}
                  >
                     {
                        options.map((singleOption) => {
                           var visible = true;
                           var usrRoles = this.getUserRoles();
                           var usrPerms = this.getUserPerms();
                           if (singleOption.APIKey !== undefined)
                              if (!usrPerms.includes(singleOption.APIKey))
                                 visible = false;

                           if (singleOption.visibleBy !== undefined)
                              if (!usrRoles.includes(Roles.ADMIN || Roles.BACKOFFICE))
                                 visible = false;

                           if (visible)
                              return this.getMenuItem({ submenuStyle, submenuColor, singleOption })
                        })}
                  </Menu>
               </Scrollbars>
            </Sider>
         </SidebarWrapper>
      );
   }

   componentDidMount() {
      TOKEN = getToken();
      this.fetch();
   }

   fetch = () => {
      axios.get(`${SERVER_ADDRESS}/api/Backoffice/User/GetUserVM`, {
         headers: {
            Authorization: `Bearer ${TOKEN}`
         }
      }).then(x => {
         this.setState({
            userRoles: x.data.roles,
            userSideBarPerms: x.data.checkedSidebarVis
         });
      });
   }

   getUserRoles = () => {
      return this.state.userRoles;
   }

   getUserPerms = () => {
      return this.state.userSideBarPerms;
   }
}

export default connect(
   state => ({
      app: state.App,
      height: state.App.height,
      locale: state.LanguageProvider.language.locale,
   }),
   { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
