// export default {
// 	apiUrl: 'http://localhost:15404/',
// };

const DateObj = new Date();
const year = DateObj.getFullYear();
const siteConfig = {
	siteName: 'Veolia Customer Hub',
	siteIcon: 'ion-ios-circle-outline',
	footerText: 'Veolia © ' + year,
};
const themeConfig = {
	topbar: 'themedefault',
	sidebar: 'themedefault',
	layout: 'themedefault',
	theme: 'themedefault',
};
const language = 'english';

const SERVER_ADDRESS = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : '';
// const SERVER_ADDRESS = 'https://portalclientes.veolia.es';

const SortTypes = {
	DESC: "desc",
	ASC: "asc",
}

const EmailLogStatus = {
	ERROR: "Error",
	SUCCESS: "Success",
}

export { siteConfig, language, themeConfig, SERVER_ADDRESS, SortTypes, EmailLogStatus };
