import AppLocale from "../../languageProvider/index";

import englishFlag from "../../image/flag/uk.svg";
import spanishFlag from "../../image/flag/spain.svg";
import portugalFlag from "../../image/flag/portugal.svg";

var defaultLanguage = "spanish";

if (window.navigator.language.includes(AppLocale.en.code)) {
    defaultLanguage = "english";
}
if (window.navigator.language.includes(AppLocale.es.code)) {
    defaultLanguage = "spanish";
}
if (window.navigator.language.includes(AppLocale.pt.code)) {
    defaultLanguage = "portuguese";
}

const config = {
    defaultLanguage: defaultLanguage,
    options: [
        {
            languageId: 'english',
            locale: 'en',
            code: AppLocale.en.code,
            text: 'English',
            icon: englishFlag,
            fullObj: AppLocale.en
        },
        {
            languageId: 'spanish',
            locale: 'es',
            code: AppLocale.es.code,
            text: 'Espanol',
            icon: spanishFlag,
            fullObj: AppLocale.es
        },
        {
            languageId: 'portuguese',
            locale: 'pt',
            code: AppLocale.pt.code,
            text: 'Português',
            icon: portugalFlag,
            fullObj: AppLocale.pt
        }
    ]
}

export function getCurrentLanguage(lang) {
    let selectedLanguage = config.options[0];
    config.options.forEach(xLang => {
        if (xLang.languageId === lang.toLowerCase()) {
            selectedLanguage = xLang;
        }
    });

    return selectedLanguage;
}

export default config;