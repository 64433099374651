import axios from 'axios';
import { SERVER_ADDRESS } from '../settings';
import SuperFetch from './superFetch';

class AuthHelper {
  login = async userInfo => {
    var resp = null;
    if (!userInfo.username || !userInfo.password) {
      return { error: "¡El nombre de usuario y la contraseña son obligatorios!" };
    }

    await SuperFetch.post('/token', userInfo).then(response => {
      if (typeof response == "undefined") {
        response = { error: "¡Verifique las credenciales dadas!" };
      } else {
        if (response == "NotAllowed") {
          response = { error: "Usuario deshabilitado!" };
        }
        if (response == "Failed") {
          response = { error: "¡Verifique las credenciales dadas!" };
        }
      }

      if (!response) {
        this.obtainTicketCredentials(response.access_token)
          .then((valid) => {
          });
      }

      let currDate = new Date();
      let expireDate = new Date(currDate.setMinutes(currDate.getMinutes() + response.expires_in));
      localStorage.setItem("tokenExpires", expireDate.toString());
      resp = response;
    }).catch(exc => { });

    return resp;
  };

  // De momento apenas valida se o token existe, futuramente deveríamos de guardar a data de expiração do token e comparar com a data atual.
  checkExpirity = (token) => {
    let tExpiration = localStorage.getItem("tokenExpires");
    let expireDate = new Date(tExpiration);
    let currDate = new Date();
    if (currDate >= expireDate || this.isEmpty(tExpiration)) {
      return "LOGOUT";
    }

    return { token };
  };

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  async checkDemoPage(token) {
    return await axios.get(`${SERVER_ADDRESS}/api/Example/IsTokenValid`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        return ({
          status: response.statusText,
          message: response.status === 200 ? response.data : "An error message!"
        });
      })
      .catch(error => ({ error: error }))
  };

  obtainTicketCredentials = async (access_token) => {
    return await axios.get(`${SERVER_ADDRESS}/api/Backoffice/User/GetUserTicketCredentials`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`
        },
      }
    )
      .then(data => {
        localStorage.setItem('ticketCredentials', JSON.stringify(data.data));
      });
  };
}

export default new AuthHelper();