import React from 'react';
import { Link } from 'react-router-dom';
// import { siteConfig } from '../../settings';
import logo from "../../image/veoliasmall.png";//"../../image/banner4.jpg";
import biglogo from "../../image/RGB_VEOLIA_HD logo white.png";

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              {/* <i className={siteConfig.siteIcon} /> */}
              <img src={logo} height="35" width="35" alt="Veolia Logo" />
            </Link>
          </h3>
        </div>
      ) : (
          <h3>
            <Link to="/dashboard">
              <img src={biglogo} height="80%" width="80%" alt="Veolia Logo" />
            </Link>
          </h3>
        )}
    </div>
  );
};
