import { Roles } from '../../helpers/utility';

// let ticketCredentials = getTicketCredentials();

let options = [
	{
		key: 'Home',
		label: 'sidebar.HomePageLabel',
		leftIcon: 'ion-android-home',
	},
	{
		// Empty Space
		key: 'Space_001',
		space: 'DKO',
	},
	{
		key: 'Separator_001',
		separatorTitle: "sidebar.customerArea",
	},
	{
		key: '',
		label: 'forms.progressBar.dashboardTitle',
		leftIcon: 'ion-speedometer',
		APIKey: 'DashboardTab'
	},
	{
		key: 'Reports',
		label: 'sidebar.Reports',
		leftIcon: 'ion-clipboard',
		APIKey: 'ReportsTab'
	},
	{
		key: 'ContractOrganization',
		label: 'sidebar.ContractOrganization',
		leftIcon: 'ion-person',
		APIKey: 'ContractOrganizationTab'
	},
	{
		key: 'SearchDocuments',
		label: 'sidebar.searchDocuments',
		leftIcon: 'ion-search',
		APIKey: 'SearchDocsTab'
	},
	{
		key: 'MyTickets',
		label: 'sidebar.myTickets',
		leftIcon: 'ion-clipboard',
		// url: getTicketCredentials(),
		APIKey: 'TicketTab'
	},
	{
		// Empty Space //paspalho 02
		key: 'Space_002',
		space: 'DKO',
	},
	{
		key: 'Separator_002',
		separatorTitle: "sidebar.restrictedArea",
		visibleBy: [Roles.ADMIN, Roles.BACKOFFICE]
	},
	{
		key: 'BackOffice',
		label: 'sidebar.backOffice',
		leftIcon: 'ion-android-settings',
		children: [
			{
				key: 'Users',
				label: 'sidebar.users',
			},
			{
				key: 'Utilizadores',
				label: 'sidebar.contacts',
			},
			{
				key: 'Companies',
				label: 'sidebar.Companies',
			},
			{
				key: 'Buildings',
				label: 'backoffice.Buildings',
			},
			{
				key: 'Logs',
				label: 'sidebar.logs',
			},
			{
				key: 'EmailLogs',
				label: 'sidebar.emailLogs',

			},
			{
				key: 'LoginLogs',
				label: 'sidebar.loginLogs',

			},
			{
				key: 'ContentManagement',
				label: 'backoffice.contentManagement',

			},
			{
				key: 'Settings',
				label: 'sidebar.Settings',
			}
		],
		visibleBy: [Roles.ADMIN, Roles.BACKOFFICE]
	},
];

export default options;
