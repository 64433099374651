import config, {
   getCurrentLanguage
} from "./config";

import actions from './actions';

const initState = {
   language: getCurrentLanguage(config.defaultLanguage || 'english')
};

export default function(state = initState, action) {
   switch (action.type) {
      case actions.CHANGE_LANGUAGE:
         return {
            ...state,
            language: action.language
         };
      default:
         return state;
   }
}