import React, { Component } from 'react';
import axios from 'axios';
// import { connect } from 'react-redux';
import { InputSearch } from '../../components/uielements/input';
import TopbarSearchModal from './topbarSearchModal.style';
import IntlMessages from '../../components/utility/intlMessages';
import { PropTypes } from 'prop-types';
import { ContextArr, ContextCodes } from "./SearchContext/config";
import Select, { SelectOption } from '../../components/uielements/select';
import { Fieldset, Label } from '../BackOffice/demo.style';
import { getToken } from '../../helpers/utility';
import { SERVER_ADDRESS } from '../../settings';
import { Row, Col, Button } from 'antd';
import { Link } from "react-router-dom";
import { saveAs } from 'file-saver';

const qs = require('qs');
var TOKEN = getToken();

export default class TopbarSearch extends Component {
   constructor(props) {
      super(props);

      this.state = {
         search: null,
         selectedContext: [
            ContextCodes.CurrentAcc,
            ContextCodes.Trips,
            ContextCodes.ContractLines,
            ContextCodes.Shipment,
            ContextCodes.Invoices,
            ContextCodes.Orders,
            ContextCodes.OtherDocuments
         ],
         visible: false,
         data: null
      }
   }

   render() {
      return (
         <>
            <div onClick={this.showModal}>
               <i
                  className="ion-ios-search-strong"
                  style={{ color: /*customizedTheme.textColor*/ "#FFF" }}
               />
            </div>
            {
               this.state.visible ?
                  <TopbarSearchModal
                     visible={this.state.visible}
                     onCancel={this.handleCancel}
                     onClose={this.handleCancel}
                     title="Global Search"
                     wrapClassName="isoSearchModal"
                     width="60%"
                     footer={<Button onClick={() => {
                        this.showModal("Cancel");
                     }}>
                        <IntlMessages id="forms.cancel" />
                     </Button>}
                  >
                     <Select
                        mode="multiple"
                        onChange={this.changeSelectedContext}
                        style={{ width: '100%' }}
                        showSearch={true}
                        allowClear={true}
                        placeholder={this.context.intl.formatMessage({ id: "searchGlobal.FilterContext" })}
                        value={this.state.selectedContext != null ? this.state.selectedContext : undefined}
                     >
                        {ContextArr.map((l =>
                           <SelectOption value={l.value} key={l.value}>
                              <IntlMessages id={`searchGlobal.${l.value}`} />
                           </SelectOption>))
                        }
                     </Select>
                     <div className="isoSearchContainer">
                        <InputSearch
                           id="InputTopbarSearch"
                           size="large"
                           placeholder={this.context.intl.formatMessage({ id: "searchGlobal.placeholder" })}
                           value={this.state.search}
                           onChange={this.handleKeyPress.bind(this)}
                        />
                        <br />
                        <Fieldset>
                           {
                              this.state.data != null ?
                                 this.searchGlobalResult()
                                 :
                                 null
                           }
                        </Fieldset>
                     </div>
                  </TopbarSearchModal>
                  : ''
            }
         </>
      );
   }

   handleCancel = () => {
      this.setState({
         visible: false
      });
   };
   handleBlur = () => {
      setTimeout(() => {
         this.setState({
            visible: false
         });
      }, 200);
   };

   showModal = (ActionBtn) => {
      if (ActionBtn === "Cancel")
         this.setState({
            visible: false
         });
      else
         this.setState({
            visible: true
         });
   };

   handleKeyPress = (event) => {
      this.setState({ search: event.target.value }, () => this.handleGlobalSearch());
   }

   handleGlobalSearch = () => {
      if (this.state.search != null) {
         if (this.state.search.length >= 5) {
            TOKEN = getToken();
            axios.get(`${SERVER_ADDRESS}/api/GlobalSearch/getGlobalInformation`, {
               headers: {
                  Authorization: `Bearer ${TOKEN}`
               },
               params: {
                  Contexts: this.state.selectedContext,
                  search: this.state.search
               },
               paramsSerializer: params => {
                  return qs.stringify(params)
               }
            }).then(resp => {
               this.setState({ data: resp.data });
            })
         } else {
            this.setState({ data: null });
         }
      } else {
         this.setState({ data: null });
      }
   }

   changeSelectedContext = value => {
      this.setState({ selectedContext: value, data: null, search: null });
   }

   searchGlobalResult = () => {
      var Info = this.state.data;
      return (
         <div style={{ paddingBottom: "10px" }}>
            {
               Info.currentAccounts.length > 0 ?
                  <div style={{ paddingLeft: "45px", paddingRight: "45px" }}>
                     <div style={{ backgroundColor: "#f1f3f6", paddingLeft: "15px" }}>
                        <Link to="/dashboard/CurrentAccount" onClick={this.handleBlur} style={{ color: "black" }}>
                           <b style={{ fontSize: 20 }}><IntlMessages id="sidebar.currentAccount" /></b>
                        </Link>
                     </div>
                     <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                        {
                           Info.currentAccounts.map(x => (
                              <div style={{ paddingTop: "8px", paddingBottom: "8px", borderBottom: "1px solid", borderBottomColor: "#f1f3f6" }}>
                                 <Row style={{ paddingLeft: "15px" }}>
                                    <Label id={`CurrAcc-${x.id}`} >
                                       {x.description}
                                    </Label>
                                 </Row>
                              </div>
                           ))
                        }
                     </div>
                  </div>
                  :
                  null
            }
            {
               Info.searchDocuments.length > 0 ?
                  <div style={{ paddingLeft: "45px", paddingRight: "45px" }}>
                     <div style={{ backgroundColor: "#f1f3f6", paddingLeft: "15px" }}>
                        <Link to="/dashboard/SearchDocuments" onClick={this.handleBlur} style={{ color: "black" }}>
                           <b style={{ fontSize: 20 }}><IntlMessages id="sidebar.searchDocuments" /></b>
                        </Link>
                     </div>
                     <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                        {
                           Info.searchDocuments.map(x => {
                              if (x.searchDocInfos.length > 0) {
                                 return (
                                    <div style={{ paddingTop: "8px" }}>
                                       <div style={{ backgroundColor: "#f1f3f6", paddingLeft: "15px" }}>
                                          <b style={{ fontSize: 20 }}><IntlMessages id={`searchGlobal.${x.name}`} /></b>
                                       </div>
                                       {
                                          x.searchDocInfos.map(z => (
                                             <div style={{ paddingTop: "8px", paddingBottom: "8px", borderTop: "1px solid", borderTopColor: "#f1f3f6" }}>
                                                <Col style={{ paddingLeft: "15px" }}>
                                                   <Label style={z.downloadLink != null ? { float: "left" } : {}} id={`searchDoc-${x.name}`} >
                                                      {z.description}
                                                   </Label>
                                                   <Col align="right">

                                                      {
                                                         z.downloadLink != null ?
                                                            <Button
                                                               type="primary"
                                                               icon="download"
                                                               style={{ align: "right" }}
                                                               onClick={this.handleDownload.bind(this, z.downloadLink, z.id)}
                                                            />
                                                            :
                                                            null
                                                      }
                                                   </Col>
                                                </Col>
                                             </div>
                                          ))
                                       }
                                    </div>
                                 )
                              }
                           })
                        }
                     </div>
                  </div>
                  :
                  null
            }
            {
               Info.trips.length > 0 ?
                  <div style={{ paddingLeft: "45px", paddingRight: "45px" }}>
                     <div style={{ backgroundColor: "#f1f3f6", paddingLeft: "15px" }}>
                        <b style={{ fontSize: 20 }}><IntlMessages id="sidebar.Trips" /></b>
                     </div>
                     <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                        {
                           Info.trips.map(x => (
                              <div style={{ paddingTop: "8px", paddingBottom: "8px", borderBottom: "1px solid", borderBottomColor: "#f1f3f6" }}>
                                 <Row style={{ paddingLeft: "15px" }}>
                                    <Label id={`Trips-${x.id}`} >
                                       {x.description}
                                    </Label>
                                 </Row>
                              </div>
                           ))
                        }
                     </div>
                  </div>
                  :
                  null
            }
         </div>
      )
   }

   handleDownload = (link, id, event) => {
      axios.get(`${SERVER_ADDRESS}${link}${id}`,
         {
            headers: {
               Authorization: `Bearer ${TOKEN}`,
            }
         }
      ).then(res => {
         this.downloadFile(res.data);
      })
   };

   downloadFile = (path) => {
      axios.get(`${SERVER_ADDRESS}/api/Common/DownloadFile`,
         {
            headers: {
               Authorization: `Bearer ${TOKEN}`,
               'Content-Type': 'application/json',
            },
            params: {
               FilePath: path
            },
            responseType: 'blob'
         }
      ).then(res => {
         var blob = new Blob([res.data], { type: res.headers["content-type"] });

         // Extract filename from path
         const fileName = /([^\\]+)$/.exec(path)[1];

         saveAs(blob, fileName);
      })
   }
}

TopbarSearch.contextTypes = {
   intl: PropTypes.object.isRequired,
};

// export default connect(state => ({
//   ...state.App,
//   // customizedTheme: state.ThemeSwitcher.topbarTheme
// }))(TopbarSearch);
