import React, { Component } from 'react';
import { Popover } from 'antd';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import { getToken } from '../../helpers/utility';
import { SERVER_ADDRESS } from '../../settings';
import { Link } from "react-router-dom";
import Modal from '../../components/feedback/modal';
import { Fieldset, Form, Label } from '../BackOffice/demo.style';
import Input, { Textarea } from '../../components/uielements/input';
import Button from "../../components/uielements/button";
import authAction from "../../redux/auth/actions";
import { connect } from 'react-redux';

const { logout } = authAction;

var TOKEN = "";

const REQUESTLOG_BASE = {
   id: null,
   email: null,
   phoneNumber: null,
   name: null,
   presentation: null
}

class TopbarNotification extends Component {
   constructor(props) {
      super(props);

      this.state = {
         visible: false,
         modalVisible: false,
         data: [],
         selectedData: {
            ...REQUESTLOG_BASE
         }
      };

      this.handleVisibleChange = this.handleVisibleChange.bind(this);
      this.hide = this.hide.bind(this);
      this.sendToNotification = this.sendToNotification.bind(this);
      this.onCloseModal = this.onCloseModal.bind(this);
   }

   hide() {
      this.setState({ visible: false });
   }

   handleVisibleChange() {
      if (!this.state.visible == true)
      {
         var NotfsId = [];

         this.state.data.map(x => {
            NotfsId.push(x.id);
         });

         axios
            .post(`${SERVER_ADDRESS}/api/Notification/MarkAsViewed`, NotfsId, {
               headers: {
                  Authorization: `Bearer ${TOKEN}`
               }
            });
      }

      this.setState({ visible: !this.state.visible });
   }

   render() {
      const content = (
         <TopbarDropdownWrapper className="topbarNotification">
            <div className="isoDropdownHeader">
               <h3>
                  <IntlMessages id="sidebar.notification" />
               </h3>
            </div>
            <div className="isoDropdownBody">

            </div>

            <Link onClick={this.sendToNotification} className="isoViewAllBtn" to="/dashboard/MyMessages">
               <IntlMessages id="topbar.viewAll" />
            </Link>
         </TopbarDropdownWrapper>
      );

      return (
         <>
            <Modal
               maskClosable={false}
               visible={this.state.modalVisible}
               onClose={this.onCloseModal}
               title={<IntlMessages id="sidebar.notification" />}
               onCancel={this.onCloseModal}
               cancelText={<IntlMessages id="forms.cancel" />}
               footer={
                  <Button
                     onClick={this.onCloseModal}
                  >
                     <IntlMessages id="forms.cancel" />
                  </Button>
               }
            >
               <Form>
                  <Fieldset>
                     <Label><IntlMessages id="antTable.title.name" /></Label>
                     <Input
                        disabled
                        value={this.state.selectedData.name}
                        placeholder={this.context.intl.formatMessage({ id: "placeHolder.name" })}
                     />
                  </Fieldset>

                  <Fieldset>
                     <Label><IntlMessages id="sidebar.email" /></Label>
                     <Input
                        disabled
                        value={this.state.selectedData.email}
                        placeholder={this.context.intl.formatMessage({ id: "placeHolder.email" })}
                     />
                  </Fieldset>

                  <Fieldset>
                     <Label><IntlMessages id="page.label.PhoneNumber" /></Label>
                     <Input
                        disabled
                        value={this.state.selectedData.phoneNumber}
                        placeholder={this.context.intl.formatMessage({ id: "page.label.PhoneNumber" }) + "..."}
                     />
                  </Fieldset>

                  <Fieldset>
                     <Label><IntlMessages id="forms.label.Presentation" /></Label>
                     <Textarea
                        disabled
                        rows={4}
                        value={this.state.selectedData.presentation}
                        placeholder={this.context.intl.formatMessage({ id: "forms.label.Presentation" })}
                     />
                  </Fieldset>

               </Form>
            </Modal>
            <Popover
               content={content}
               trigger="click"
               visible={this.state.visible}
               onVisibleChange={this.handleVisibleChange}
               placement="bottomLeft"
            >
               <div className="isoIconWrapper">
                  <i
                     className="ion-android-notifications"
                     style={{ color: "white" }}
                  />
                  <span>{this.state.data.length}</span>
               </div>
            </Popover>
         </>
      );
   }

   componentDidMount() {
      TOKEN = getToken();
      this.fetch();
   }

   fetch = () => {
      axios.get(`${SERVER_ADDRESS}/api/Notification/GetNotifications`, {
         headers: {
            Authorization: `Bearer ${TOKEN}`
         }
      }).then(data => {
         this.setState({ data: data.data });
      })
      .catch(ex => {
         logout();
      });
   }

   handleNotificationClick = (id) => {
      var auxData = {};

      this.state.data.find(x => {
         if (x.refId != null)
         {
            if (x.refId == id)
            {
               auxData = x.requestLog;
            }
         }
      });

      this.setState({ visible: false, selectedData: auxData });
      this.toggleModal();
   }

   toggleModal = () => {
      this.setState((prevState) => ({ modalVisible: !prevState.modalVisible }));
   }

   sendToNotification() {
      this.setState({ visible: false });
   }

   onCloseModal() {
      this.setState({
         selectedData: {
            ...REQUESTLOG_BASE
         }
      });
      this.toggleModal();
   }
}

TopbarNotification.contextTypes = {
   intl: PropTypes.object.isRequired,
};

export default connect(
   null,
   { logout }
)(TopbarNotification);