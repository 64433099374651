import antdPt from 'antd/lib/locale-provider/pt_PT';
import appLocaleData from 'react-intl/locale-data/pt';
import ptMessages from '../locales/pt_PT.json';

const PtLang = {
   messages: {
      ...ptMessages,
   },
   antd: antdPt,
   locale: 'pt-Pt',
   code: 'PT',
   data: appLocaleData,
};

export default PtLang;
