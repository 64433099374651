import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import axios from 'axios';

import { getToken } from '../../helpers/utility';
import { SERVER_ADDRESS } from '../../settings';
import authAction from '../../redux/auth/actions';
import IntlMessages from '../../components/utility/intlMessages';
import Popover from '../../components/uielements/popover';
import TopbarDropdownWrapper from './topbarDropdown.style';
import userpic from '../../image/user1.png';


const { logout } = authAction;
var TOKEN = "";

class TopbarUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
		};
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.hide = this.hide.bind(this);
	}

	hide() {
		this.setState({ visible: false });
	}

	handleVisibleChange() {
		this.setState({ visible: !this.state.visible });
	}

	// handleSettingsClick() {
	//    this.props.history.push("/dashboard/Utilizadores");
	// }

	render() {
		const content = (
			<TopbarDropdownWrapper className="isoUserDropdown">
				<Link to="/dashboard/Utilizadores" className="isoDropdownLink">
					<IntlMessages id="themeSwitcher.settings" />
				</Link>

				{/* 
					<a className="isoDropdownLink" href="# ">
					   <IntlMessages id="sidebar.feedback" />
					</a>
					<a className="isoDropdownLink" href="# ">
					   <IntlMessages id="topbar.help" />
					</a> 
				*/}

				<a className="isoDropdownLink" onClick={this.props.logout} href="# ">
					<IntlMessages id="topbar.logout" />
				</a>
			</TopbarDropdownWrapper>
		);

		let src = userpic;
		if (this.state.b64 != null) {
			src = this.state.b64;
		}

		return (
			<Popover
				content={content}
				trigger="click"
				visible={this.state.visible}
				onVisibleChange={this.handleVisibleChange}
				arrowPointAtCenter={true}
				placement="bottomLeft"
			>
				<div className="isoImgWrapper">
					<img alt="user" src={src} />
				</div>
			</Popover>
		);
	}

   componentDidMount() {
      TOKEN = getToken();
      this.fetch();
   }

   fetch = () => {
      axios.get(`${SERVER_ADDRESS}/api/Backoffice/User/GetUserPhoto`,
      {
         headers: {
            Authorization: `Bearer ${TOKEN}`
         },
      }
   )
      .then(data => {
         const b64 = `data:image/png;base64,${data.data}`;
         if (data.data)
            this.setState({ b64: b64 });
      })
      // .catch(exc => { debugger; })
   }
}

export default connect(
	null,
	{ logout }
)(TopbarUser);
