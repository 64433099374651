import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Debounce } from 'react-throttle';
import { IntlProvider } from "react-intl";
import { Layout, LocaleProvider, Alert } from 'antd';
import Button from '../../components/uielements/button';
import { PropTypes } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import axios from "axios";
import WindowResizeListener from 'react-window-size-listener';
import config from "../../redux/languageSwitcher/config";
import { AppLocale } from '../../dashApp';
import { getToken } from '../../helpers/utility';
import { siteConfig, themeConfig, SERVER_ADDRESS } from '../../settings';
import appActions from '../../redux/app/actions';
import AppHolder from './commonStyle';
import AppRouter from './AppRouter';
import authAction from '../../redux/auth/actions';
import Modals from "../../components/feedback/modal";
import Sidebar from '../Sidebar/Sidebar';
import themes from '../../settings/themes';
import Topbar from '../Topbar/Topbar';
import IntlMessages from '../../components/utility/intlMessages';
import './global.css';
import ButtonGroup from 'antd/lib/button/button-group';
import languageActions from "../../redux/languageSwitcher/actions";

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
const { changeLanguage } = languageActions;
const confirm = Modals.confirm;

var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();

var TOKEN = "";

const INIT_COOKIE = {
   title: null,
   text: null
}

export class App extends Component {
   constructor(props) {
      super(props);
      this.state = {
         gdprData: null,
         showCookie: localStorage.getItem("AcceptedCookie"),
         cookieTextShow: false,
         CookieInformation: {
            ...INIT_COOKIE
         },
         chatUrl: "",
         usrLang: "",
         showChat: false,
      };
   }

   componentDidMount() {
      TOKEN = getToken();
      this.showsGDPR();
      this.getLatestCookie();
      this.getUserLanguage();
      this.getChatUrl();
      this.showChat();

      var iframe = null;
      window.addEventListener('message', function (e) {
         if (e.data == 'minimizeIframe') {
            iframe = document.getElementById("customer-chat-iframe")
            iframe.style.height = "50px";
            iframe.contentWindow.postMessage("scrollChat", "*");
         }

         if (e.data == 'maximizeIframe') {
            iframe = document.getElementById("customer-chat-iframe")
            iframe.style.height = "470px";
            iframe.contentWindow.postMessage("scrollChat", "*");
         }
      });
   }

   render() {
      const { url } = this.props.match;
      const { height, locale, changeLanguage } = this.props;
      const appHeight = window.innerHeight;
      const currentAppLocale = AppLocale[locale];

      return (
         <LocaleProvider locale={currentAppLocale.antd}>
            <IntlProvider
               locale={currentAppLocale.locale}
               messages={currentAppLocale.messages}
            >
               <ThemeProvider theme={themes[themeConfig.theme]}>
                  <AppHolder>
                     <Layout style={{ height: appHeight }}>
                        <Debounce time="1000" handler="onResize">
                           <WindowResizeListener
                              onResize={windowSize =>
                                 this.props.toggleAll(
                                    windowSize.windowWidth,
                                    windowSize.windowHeight
                                 )
                              }
                           />
                        </Debounce>
                        <Topbar url={url} userLang={this.state.usrLang} />
                        <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                           <Sidebar url={url} />
                           <Layout
                              className="isoContentMainLayout"
                              style={{
                                 height: height
                              }}
                           >
                              <Content
                                 className="isomorphicContent"
                                 style={{
                                    padding: '60px 0 0',
                                    flexShrink: '0',
                                    background: '#f1f3f6',
                                    position: 'relative'
                                 }}
                              >
                                 {
                                    !this.state.gdprData && <AppRouter url={url} />
                                 }

                              </Content>
                              <Footer
                                 style={{
                                    background: '#ffffff',
                                    textAlign: 'center',
                                    borderTop: '1px solid #ededed'
                                 }}
                              >
                                 {siteConfig.footerText}
                              </Footer>
                           </Layout>
                        </Layout>
                        {
                           !this.state.showCookie && this.state.CookieInformation.text != null ?
                              <Alert
                                 message={
                                    <div>
                                       <h2 style={{ color: "white" }}>{this.state.CookieInformation.title}</h2>
                                       <div style={{ color: "white" }} onClick={() => this.setState({ cookieTextShow: !this.state.cookieTextShow })}>
                                          <IntlMessages id="feedback.CookieInitMsg" />
                                       </div>
                                       <div style={{ color: "white" }} hidden={!this.state.cookieTextShow}>
                                          {htmlToReactParser.parse(this.state.CookieInformation.text)}
                                       </div>
                                       <ButtonGroup style={{ float: "right" }}>
                                          <Button
                                             onClick={() => this.setState({ cookieTextShow: !this.state.cookieTextShow })}
                                             style={{ backgroundColor: "#b51933", borderColor: "#b51933", color: "white" }}
                                          >
                                             <IntlMessages id="feedback.CookieShowMore" />
                                          </Button>
                                          <Button
                                             onClick={() => {
                                                this.setState({ showCookie: !this.state.showCookie });
                                                localStorage.setItem("AcceptedCookie", true)
                                             }}
                                             style={{ backgroundColor: "#b51933", borderColor: "#b51933", color: "white" }}
                                          >
                                             <IntlMessages id="common.Accept" />
                                          </Button>
                                       </ButtonGroup>
                                    </div>
                                 }
                                 type="info"
                                 banner
                                 onClose={() => { localStorage.setItem("AcceptedCookie", true) }}
                                 showIcon={false}
                                 style={{ position: "absolute", left: "15%", right: "25%", bottom: 0, zIndex: 1, backgroundColor: "#1d1d1d" }}
                              />
                              :
                              null
                        }
                        {
                           window.innerWidth <= 500 ?
                              null
                              :
                              this.state.showChat == false ?
                                 <iframe name="customer-chat-iframe"
                                    id="customer-chat-iframe"
                                    src={this.state.chatUrl}
                                    marginwidth="0"
                                    marginheight="0"
                                    frameborder="0"
                                    style={{
                                       display: 'block', background: 'transparent', border: 'none', outline: 'none',
                                       position: 'fixed', zIndex: '999999', overflow: 'hidden', bottom: '0px', right: '50px', width: '370px',
                                       height: '55px', margin: '0px', padding: '0px'
                                    }}
                                 />
                                 : null
                        }
                     </Layout>
                  </AppHolder>
               </ThemeProvider>
            </IntlProvider>
         </LocaleProvider>
      );
   }

   getUserLanguage = () => {
      const { changeLanguage } = this.props;
      axios.get(`${SERVER_ADDRESS}/api/Account/GetUserLanguage`, {
         headers: {
            Authorization: `Bearer ${TOKEN}`
         }
      })
         .then(resp => {
            var aux = 'english';
            var auxCode = resp.data.substring(0, 2);

            config.options.find(x => {
               if (auxCode == x.locale) {
                  aux = x.languageId;
               }
            });

            this.setState({
               usrLang: resp.data.substring(3)
            }, () => {
               changeLanguage(aux);
            })
         });
   }

   showChat = () => {
      axios
         .get(`${SERVER_ADDRESS}/api/Backoffice/Common/ShowChat`)
         .then(resp => {
            this.setState({ showChat: resp.data });
         });
   }

   ShowPrivacyPolicyModal = () => {
      const gdpr = this.state.gdprData;

      confirm({
         title: gdpr.title,
         content: <div dangerouslySetInnerHTML={{ __html: gdpr.text }}></div>,
         okText: this.context.intl.formatMessage({ id: "common.Accept" }),
         cancelText: this.context.intl.formatMessage({ id: "common.Decline" }),
         width: "65%",
         onOk: () => {
            this.CreateGDPRAcceptance(true);
         },
         onCancel: () => {
            this.CreateGDPRAcceptance(false);
         },
      });
   }

   getLatestCookie = () => {
      axios
         .get(`${SERVER_ADDRESS}/api/Backoffice/Cookie/GetTheLatestCookie?localeCode=${window.navigator.language}`)
         .then(resp => {
            this.setState({ CookieInformation: resp.data });
         });
   }

   GetOlderGDPRToApprove = () => {
      axios
         .get(`${SERVER_ADDRESS}/api/Backoffice/PrivacyPolicy/GetOlderGDPRToApprove`, {
            headers: {
               Authorization: `Bearer ${TOKEN}`
            }
         })
         .then(data => {
            if (data.data) {
               this.setState(
                  { gdprData: data.data },
                  () => { this.ShowPrivacyPolicyModal(); }
               );
            }
            else {
               this.setState({ gdprData: null });
            }
         });
   }

   showsGDPR = () => {
      axios
         .get(`${SERVER_ADDRESS}/api/Backoffice/PrivacyPolicy/ShowsGDPR`, {
            headers: {
               Authorization: `Bearer ${TOKEN}`
            }
         })
         .then(data => {
            if (data.data == false) {
               this.GetOlderGDPRToApprove();
            }
         });
   }

   getChatUrl = () => {
      axios
         .get(`${SERVER_ADDRESS}/api/Backoffice/Settings/GetChatUrl`, {
            headers: {
               Authorization: `Bearer ${TOKEN}`
            }
         })
         .then(data => {
            this.setState({ chatUrl: data.data });
         })
   }

   CreateGDPRAcceptance = (accepted) => {
      const policy = {
         id: this.state.gdprData.id,
         accepted: accepted
      }

      axios
         .post(`${SERVER_ADDRESS}/api/Backoffice/PrivacyPolicy/CreateGDPRAcceptance`,
            policy,
            {
               headers: {
                  Authorization: `Bearer ${TOKEN}`
               },
            })
         .then(() => {
            if (!accepted) {
               localStorage.clear();
               window.location.reload();
            }
            else {
               this.GetOlderGDPRToApprove();
            }
         })
   }
}

App.contextTypes = {
   intl: PropTypes.object.isRequired,
};

export default connect(
   state => ({
      auth: state.Auth,
      height: state.App.height,
      locale: state.LanguageProvider.language.locale,
      ...state.App,
      ...state.LangaugeProvider
   }),
   { logout, toggleAll, changeLanguage }
)(App);