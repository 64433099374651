import { push } from 'react-router-redux';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clearToken, getToken, setToken } from '../../helpers/utility';
import AuthHelper from '../../helpers/authHelper';
import actions from './actions';
import notification from '../../components/notification';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { userInfo } = payload;
    const result = yield call(AuthHelper.login, userInfo);
    if (result.error !== undefined) {
      notification('error', "Oops!", result.error);
      yield put({ type: actions.LOGIN_ERROR });
    }
    else {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: result, // Não tem muita lógica visto que será apenas o token, futuramete iremos armazenar expiração do token.
        token: result//.token,
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield setToken(payload.token); // É exatamente o mesmo que "yield localStorage.setItem('token', payload.token);"
    if (typeof payload.props != "undefined") {
      payload.props.history.push('/dashboard/Home');
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    localStorage.removeItem('ticketCredentials');
    localStorage.removeItem('googleTreeFolder');
    localStorage.removeItem('googleInvoices');
    localStorage.removeItem('googleOtherDocuments');
    localStorage.removeItem('googleShipping');
    localStorage.removeItem('googleContract');
    localStorage.removeItem('tokenExpires');
    yield put(push('/signin'));
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken(); // Deveríamos validar se já expirou. | AuthHelper.checkExpirity(getToken());

    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: { token },
        token,
      });

      // yield put(push('/dashboard/Home')); // Redirecionar para a Dashboard
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
