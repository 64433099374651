import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import asyncComponent from '../../helpers/AsyncFunc';


const routes = [
	{
		path: '',
		component: asyncComponent(() => import('../dashboard')),
	},
	{
		path: 'Users',
		component: asyncComponent(() => import('../BackOffice/Users/index')),
	},
	{
		path: 'Utilizadores',
		component: asyncComponent(() => import('../BackOffice/Utilizadores/index')),
	},
	{
		path: 'SearchDocuments',
		component: asyncComponent(() => import('../SearchDocuments/index')),
	},
	{
		path: 'Logs',
		component: asyncComponent(() => import('../BackOffice/Logs/index')),
	},
	{
		path: 'EmailLogs',
		component: asyncComponent(() => import('../BackOffice/EmailLogs/index')),
	},
	{
		path: 'LoginLogs',
		component: asyncComponent(() => import('../BackOffice/LoginLogs/index')),
	},
	{
		path: 'Settings',
		component: asyncComponent(() => import('../BackOffice/Settings/index')),
	},
	{
		path: 'CurrentAccount',
		component: asyncComponent(() => import('../CurrentAccount/index')),
	},
	{
		path: 'MyTickets',
		component: asyncComponent(() => import('../MyTickets/index')),
	},
	{
		path: 'MyMessages',
		component: asyncComponent(() => import('../MyMessages/index')),
	},
	{
		path: 'Home',
		component: asyncComponent(() => import('../HomePage/index')),
	},
	{
		path: 'Contracts',
		component: asyncComponent(() => import('../Contracts/index')),
	},
	{
		path: 'ContractsGeo',
		component: asyncComponent(() => import('../ContractsGeo/index')),
	},
	{
		path: 'Teams',
		component: asyncComponent(() => import('../Teams/index')),
	},
	{
		path: 'Stocks',
		component: asyncComponent(() => import('../Stocks/index')),
	},
	{
		path: 'Transports',
		component: asyncComponent(() => import('../Transports/index')),
	},
	{
		path: 'ContentManagement',
		component: asyncComponent(() => import('../BackOffice/ContentManagement/index')),
	},
	{
		path: 'Trips',
		component: asyncComponent(() => import('../Trips/index')),
	},
	{
		path: 'Request',
		component: asyncComponent(() => import('../Page/Request')),
	},
	{
		path: 'Reports',
		component: asyncComponent(() =>import('../Reports/Index')),
	},
	{
		path: 'ContractOrganization',
		component: asyncComponent(() =>import('../ContractOrganization/Index')),
	},
	{
		path: 'Companies',
		component: asyncComponent(() => import('../BackOffice/Company/Index')),
	},
	{
		path: 'Buildings',
		component: asyncComponent(() => import('../BackOffice/Buildings/index')),
	}
];

class AppRouter extends Component {
	render() {
		const { url, style } = this.props;
		
		return (
			<div style={style}>
				{routes.map(singleRoute => {
					const { path, exact, ...otherProps } = singleRoute;
					return (
						<Route
							exact={exact === false ? false : true}
							key={singleRoute.path}
							path={`${url}/${singleRoute.path}`}
							{...otherProps}
						/>
					);
				})}
			</div>
		);
	}
}

export default AppRouter;
